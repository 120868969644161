<template>
  <svg
    :width="crossWidth"
    :height="crossHeight"
    viewBox="0 0 413 810"
    fill="none"
    class="z-10"
    :class="firstCrossClass"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M254.478 1.11153L160.689 0.945606L160.349 193.393L1.89782 193.113L1.70215 303.708L160.153 303.989L159.26 808.716L253.049 808.882L253.942 304.155L412.389 304.435L412.585 193.839L254.137 193.559L254.478 1.11153Z"
      :fill="`url(#paint0_linear_310_${randomNumber1})`"
    />
    <defs>
      <linearGradient :id="`paint0_linear_310_${randomNumber1}`" x1="193.835" y1="13.6971" x2="237.464" y2="871.048" gradientUnits="userSpaceOnUse">
        <stop :stop-color="color" />
        <stop offset="1" :stop-color="color" stop-opacity="0" />
      </linearGradient>
    </defs>
  </svg>

  <svg
    :width="crossWidth"
    :height="crossHeight"
    viewBox="0 0 413 810"
    fill="none"
    class="z-10"
    :class="secondCrossClass"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M254.478 1.11153L160.689 0.945606L160.349 193.393L1.89782 193.113L1.70215 303.708L160.153 303.989L159.26 808.716L253.049 808.882L253.942 304.155L412.389 304.435L412.585 193.839L254.137 193.559L254.478 1.11153Z"
      :fill="`url(#paint0_linear_310_${randomNumber2})`"
    />
    <defs>
      <linearGradient :id="`paint0_linear_310_${randomNumber2}`" x1="193.835" y1="13.6971" x2="237.464" y2="871.048" gradientUnits="userSpaceOnUse">
        <stop :stop-color="color" />
        <stop offset="1" :stop-color="color" stop-opacity="0" />
      </linearGradient>
    </defs>
  </svg>
</template>
<script setup>
const props = defineProps({
  color: {
    type: String,
    default: '#000000',
  },
  crossWidth: {
    type: String,
    default: '413',
  },
  crossHeight: {
    type: String,
    default: '810',
  },
  firstCrossClass: String,
  secondCrossClass: String,
})

const randomNumber1 = computed(() => Math.round(Math.random() * 10000))
const randomNumber2 = computed(() => Math.round(Math.random() * 10000))
</script>
